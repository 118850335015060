import * as Icons from "../../page/icons";
import * as React from "react";

import { ConfiguredLink, getLinkFromLinkingSpec, linkingSpecDefaultValues } from "../../../utils/linking.util";
import { FontConfigurationOptions, FontConfigurationSpec, defaultFontConfigurationValues } from "../../../utils/menufont.util";
import { FormSpec, IconColoring, localized, multiSelectStylePicker } from "../../../form-specs";
import { LinkingSpecOptions, linkingSpec } from "../../../inputSpecs/linkingSpec";
import { LocalizedContentBase, Site, WithId } from "@maxxton/cms-api";
import { MenuWidgetSpec, Widget } from "../../";
import { getI18nLocaleObject, getI18nLocaleString } from "../../../i18n";

import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { ImageSpec } from "../../../media";
import { PageLink } from "./PageLink";
import { PageLinkContext } from "./pageLink.util";
import { WidgetGroup } from "../../widget.enum";
import { findMultiSelectStyleClassNames } from "../../../themes";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import namespaceList from "../../../i18n/namespaceList";
import { pageLink } from "../../../routing";

export interface LocalizedExternalLink {
    url: string;
    locale: string;
}

export interface WidgetOptions {
    label: string;
    styleIds: any[];
    siteId: string | null;
    pageId: string | null;
    anchorLabel: string;
    useAsButton: boolean;
    useAsExternal: boolean;
    externalLink: LocalizedExternalLink[];
    showIcons: boolean;
    iconProperties: string;
    iconColor: IconColoring;
    iconPosition: IconPosition;
    hidden: boolean;
    openNewTab: boolean;
    noFollowLink: boolean;
    localized: LocalizedPageLink[];
    addHoverEffect: boolean;
    hoverEffect: HoverEffect;
    useAsMyEnvLogout: boolean;
    fontConfiguration: FontConfigurationOptions;
    linking: LinkingSpecOptions;
    iconType?: string;
    mxtsImage?: ImageSpec;
}

enum IconChoice {
    FONT_AWESOME = "FontAwesome",
    MXTS_IMAGE = "MXTSImage",
}

export interface LocalizedPageLink extends LocalizedContentBase {
    label: string;
}

type HoverEffect = "" | "he-1" | "he-2" | "he-3" | "he-4" | "he-5";
type IconPosition = "" | "top" | "center" | "bottom";

const iconList = Object.keys(Icons.default).map((key: any) => ({ label: (Icons.default as any)[key], value: key }));

const TARGETS = ["menu"];

const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "pagelink-widget-options",
    name: getI18nLocaleObject(namespaceList.pluginMenu, "pageLinkOptions"),
    pluralName: getI18nLocaleObject(namespaceList.pluginMenu, "pageLinkOptions"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.pluginMenu, "general"),
                    properties: [
                        [
                            localized({
                                label: getI18nLocaleObject(namespaceList.pluginMenu, "label"),
                                variable: "localized",
                                tabContent: [
                                    {
                                        variable: "label",
                                        label: getI18nLocaleObject(namespaceList.pluginMenu, "label"),
                                        type: "text",
                                    },
                                ],
                            }),
                            {
                                label: getI18nLocaleObject(namespaceList.widgetDropdownMenu, "menuItemVisibility"),
                                variable: "hidden",
                                type: "checkbox",
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.pluginMenu, "styling"),
                    properties: [
                        [
                            multiSelectStylePicker("styleIds", TARGETS),
                            FontConfigurationSpec<WidgetOptions, keyof WidgetOptions>({ variable: "fontConfiguration" }),
                            {
                                variable: "useAsButton",
                                label: getI18nLocaleObject(namespaceList.admin, "useAsButton"),
                                type: "checkbox",
                            },
                            {
                                variable: "showIcons",
                                label: getI18nLocaleObject(namespaceList.widgetWebContent, "iconTitle"),
                                type: "checkbox",
                            },
                            {
                                variable: "iconType",
                                label: getI18nLocaleObject(namespaceList.pluginMenu, "iconType"),
                                type: "select",
                                default: "fontawesome",
                                visible: (options: WidgetOptions) => options.showIcons,
                                optionList: [
                                    {
                                        value: "fontawesome",
                                        label: IconChoice.FONT_AWESOME,
                                    },
                                    {
                                        value: "mxtsimage",
                                        label: IconChoice.MXTS_IMAGE,
                                    },
                                ],
                            },
                            {
                                variable: "mxtsImage",
                                type: "image",
                                label: getI18nLocaleObject(namespaceList.widgetImage, "image"),
                                visible: (options: WidgetOptions) => options.iconType === "mxtsimage",
                            },
                            {
                                variable: "iconProperties",
                                label: getI18nLocaleObject(namespaceList.widgetWebContent, "iconProperty"),
                                type: "select",
                                visible: (options: WidgetOptions) => options.showIcons && options.iconType !== "mxtsimage",
                                optionList: iconList,
                            },
                            {
                                variable: "iconProperties",
                                type: "icons",
                                visible: (options: WidgetOptions) => options.showIcons && options.iconType === "fontawesome",
                            },
                            {
                                variable: "iconColor",
                                label: getI18nLocaleObject(namespaceList.widgetWebContent, "iconColor"),
                                type: "dual-color",
                                default: "color-brand-alt" as IconColoring,
                                visible: (options: WidgetOptions) => options.showIcons && options.iconType !== "mxtsimage",
                            },
                            {
                                variable: "addHoverEffect",
                                label: getI18nLocaleObject(namespaceList.pluginMenu, "addHoverEffect"),
                                type: "checkbox",
                            },
                            {
                                variable: "hoverEffect",
                                label: getI18nLocaleObject(namespaceList.pluginMenu, "hoverEffect"),
                                type: "select",
                                default: "" as HoverEffect,
                                optionList: [
                                    { value: "he-1", label: getI18nLocaleObject(namespaceList.pluginMenu, "underlineInBrandColor") },
                                    { value: "he-2", label: getI18nLocaleObject(namespaceList.pluginMenu, "textColorTransition") },
                                    { value: "he-3", label: getI18nLocaleObject(namespaceList.pluginMenu, "centerToTips") },
                                    { value: "he-4", label: getI18nLocaleObject(namespaceList.pluginMenu, "textToBold") },
                                    { value: "he-5", label: getI18nLocaleObject(namespaceList.pluginMenu, "scaleTheText5%") },
                                ],
                                visible: (options: WidgetOptions) => options.addHoverEffect,
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "linking"),
                    properties: [
                        [
                            linkingSpec<WidgetOptions, keyof WidgetOptions>({ variable: "linking" }),
                            {
                                variable: "useAsMyEnvLogout",
                                label: getI18nLocaleObject(namespaceList.pluginMenu, "useAsMyEnvLogout"),
                                type: "checkbox",
                            },
                        ],
                    ],
                },
            ],
        },
    ],
};

export const pageLinkWidget: MenuWidgetSpec<WidgetOptions> = {
    id: "pagelink",
    type: "menu",
    widgetGroup: WidgetGroup.OTHER,
    name: getI18nLocaleObject(namespaceList.pluginMenu, "pageLink"),
    description: getI18nLocaleObject(namespaceList.pluginMenu, "pageLinkDescription"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): WidgetOptions => ({
        label: "",
        styleIds: [],
        siteId: "",
        pageId: "",
        anchorLabel: "",
        externalLink: [],
        useAsButton: false,
        useAsExternal: false,
        localized: [],
        showIcons: false,
        iconProperties: "",
        iconColor: "color-brand",
        iconPosition: "top",
        hidden: false,
        openNewTab: false,
        noFollowLink: false,
        addHoverEffect: false,
        hoverEffect: "",
        useAsMyEnvLogout: false,
        fontConfiguration: defaultFontConfigurationValues,
        linking: linkingSpecDefaultValues,
    }),
    async instanceDescription({ widget, context }): Promise<string> {
        const { localized } = widget.options;
        const { currentLocale, site: contextSite } = context;
        const localizedPageLink: LocalizedPageLink | null = getLocalizedContent({ site: contextSite, currentLocale, localizedContent: localized });
        const label = localizedPageLink?.label;
        if (!label) {
            return getI18nLocaleString(namespaceList.pluginMenu, "pageLinkDescription");
        }
        return label;
    },
    async render(widget: Widget<WidgetOptions>, context: CMSProvidedProperties, allSites?: Array<Site & WithId>) {
        const { currentLocale, site: contextSite } = context;
        const { siteId, pageId, linking } = widget.options;
        const linkingItem = linking?.localizedLinkButtonOptions?.filter((item: { locale: string | undefined }) => item.locale === currentLocale.locale);
        const linkPageId = pageId || linkingItem?.[0].pageId;
        const linkSiteId = siteId || linkingItem?.[0].siteId;
        const link: ConfiguredLink = await getLinkFromLinkingSpec({ context, linkingSpecOptions: linking });
        let isActive = false;

        let site: (Site & WithId) | null | undefined;
        if (allSites && allSites.length > 0 && linkSiteId) {
            site = allSites.find((item) => item._id === linkSiteId);
        }
        if (!site && linkSiteId) {
            if (context.site?._id === linkSiteId) {
                site = context.site;
            } else {
                site = await context.cmsApi.siteApi.findById({ id: linkSiteId, projection: { sitemap: 0 } });
                if (!site) {
                    throw new Error("Unable to find site");
                }
            }
        }
        if (!linking) {
            let linkUrl: string | undefined;
            if (site && linkPageId) {
                const url = await pageLink({ site, pageId: linkPageId, locale: context.currentLocale, context });
                if (url && context.location.pathname === url) {
                    isActive = true;
                }
                linkUrl = url;
            }
            if (linkUrl === undefined && site) {
                const locale = context.currentLocale.locale;
                // Check valid languages for a site
                if (site.localeMultiSelect && site.localeMultiSelect.length > 0) {
                    site.localeMultiSelect.forEach((lang) => {
                        if (lang.value === locale) {
                            linkUrl = (site!.enableMultiLanguage && site!.locale.code !== locale ? "/" + locale : "") + "/404";
                        } else {
                            // Not a valid language
                            linkUrl = "/404";
                        }
                    });
                } else {
                    linkUrl = `${site.enableMultiLanguage && site.locale.code !== locale ? "/" + locale : ""}/404`;
                }
            }
            const LocalizedExternalLink = getLocalizedContent({ site: contextSite, currentLocale, localizedContent: widget.options.externalLink })?.url || "";
            link.url = widget.options.useAsExternal ? LocalizedExternalLink : linkUrl || "";
            link.rel = widget.options.noFollowLink ? "nofollow" : undefined;
            link.target = widget.options.openNewTab ? "_blank" : "_self";
            link.isExternalLink = widget.options.useAsExternal;
        } else {
            if (site && linkPageId) {
                const url = await pageLink({ site, pageId: linkPageId, locale: context.currentLocale, context });
                if (url && context.location.pathname === url) {
                    isActive = true;
                }
            }
        }

        const styleClasses = findMultiSelectStyleClassNames(context.theme, TARGETS, widget.options.styleIds);
        const localizedPageLink: LocalizedPageLink | null = getLocalizedContent({ site: contextSite, currentLocale, localizedContent: widget.options.localized });
        const label = localizedPageLink?.label || "";

        return (
            <PageLinkContext.Consumer>
                {(pageLinkContext) => (
                    <PageLink key={widget._id} options={widget.options} label={label} className={styleClasses} link={link} context={context} isActive={isActive} pageLinkContext={pageLinkContext} />
                )}
            </PageLinkContext.Consumer>
        );
    },
};
