import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as classNames from "classnames";

import { ConfiguredLink, getUrlWithAnchor } from "../../../utils/linking.util";
import { LocalizedPageLink, WidgetOptions } from "./";
import { NavItem, NavLink } from "reactstrap";
import { getInlineFontStyle, getPreconfiguredFontClassnames } from "../../../utils/fontOptions.util";

import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { LOCAL_STORAGE_KEYS } from "../../../utils/constants";
import { PageLinkContextType } from "./pageLink.util";
import { SmartLink } from "../../../components/SmartLink";
import { getLocalizedContent } from "../../../utils/localizedContent.util";

interface PageLinkProps {
    options: WidgetOptions;
    className?: string;
    label: string;
    context: CMSProvidedProperties;
    isActive: boolean;
    link: ConfiguredLink;
    pageLinkContext?: PageLinkContextType;
}
interface PageLinkState {}

export class PageLink extends React.PureComponent<PageLinkProps, PageLinkState> {
    public render(): JSX.Element | null {
        const { options, className, context, isActive, link, pageLinkContext } = this.props;
        const { currentLocale, site: contextSite } = context;
        const localizedPageLink: LocalizedPageLink | null = getLocalizedContent({ site: contextSite, currentLocale, localizedContent: options.localized });
        const label = localizedPageLink?.label || "";
        // usage of different icons here
        /* jscpd:ignore-start */

        const navItemTag = pageLinkContext?.isInsideDropdownMenu && !pageLinkContext.isInsideGroup ? "span" : "li";
        const iconClassName = options ? (
            <FontAwesome
                name={options.iconProperties || ""}
                // eslint-disable-next-line max-len
                className={`fontawesome-${options.iconProperties} icon ${
                    options.iconColor && options.iconColor.indexOf("theme") > -1 ? `color-${options.iconColor}` : options.iconColor && options.iconColor.indexOf("rgba") === -1 ? options.iconColor : ""
                }`}
                style={{ color: options.iconColor && options.iconColor.indexOf("rgba") > -1 ? options.iconColor : undefined }}
            />
        ) : undefined;
        /* jscpd:ignore-end */
        const mxtsIcon = options?.showIcons ? <span className={classNames("mxts-icon")} style={inlineIconStyles(options)}></span> : undefined;
        const showIcon = options?.showIcons && options.iconType !== "mxtsimage" ? iconClassName : mxtsIcon;
        if (options.useAsButton) {
            return (
                <NavItem tag={navItemTag} className={classNames("button", className, getPreconfiguredFontClassnames(options.fontConfiguration))} style={getInlineFontStyle(options.fontConfiguration)}>
                    {link && (
                        <NavLink tag={SmartLink} onClick={this.handleClick} href={link.url}>
                            {showIcon} {label}
                        </NavLink>
                    )}
                </NavItem>
            );
        } else if (link.isExternalLink && label) {
            return (
                <NavItem tag={navItemTag} className={(classNames(className), getPreconfiguredFontClassnames(options.fontConfiguration))} style={getInlineFontStyle(options.fontConfiguration)}>
                    {link && (
                        <NavLink
                            href={link.url}
                            tag={SmartLink}
                            className={(classNames("external-link"), getPreconfiguredFontClassnames(options.fontConfiguration))}
                            target={link.target}
                            rel={link.rel}
                            style={getInlineFontStyle(options.fontConfiguration)}
                        >
                            {showIcon} {label}
                        </NavLink>
                    )}
                </NavItem>
            );
        } else if (!link.isExternalLink && label) {
            return (
                <NavItem
                    tag={navItemTag}
                    className={classNames("localized-label regular-link", className, { "active-page-link": isActive }, getPreconfiguredFontClassnames(options.fontConfiguration))}
                    style={getInlineFontStyle(options.fontConfiguration)}
                >
                    {link && (
                        <NavLink
                            tag={SmartLink}
                            onClick={this.handleClick}
                            href={getUrlWithAnchor(link)}
                            target={link.target}
                            className={classNames(getPreconfiguredFontClassnames(options.fontConfiguration))}
                            style={getInlineFontStyle(options.fontConfiguration)}
                        >
                            {showIcon} {label}
                        </NavLink>
                    )}
                </NavItem>
            );
        } else if (!label.length || (link.isExternalLink && link.url.length < 1)) {
            return null;
        }
        return (
            <NavItem tag={navItemTag}>
                {link.url && (
                    <NavLink
                        href={link.url}
                        target={link.target}
                        onClick={this.handleClick}
                        rel={link.rel}
                        tag={SmartLink}
                        className={(classNames("nav-link", options.hoverEffect, { "active-page-link": isActive }), getPreconfiguredFontClassnames(options.fontConfiguration))}
                        style={getInlineFontStyle(options.fontConfiguration)}
                    >
                        {showIcon} {options.label}
                    </NavLink>
                )}
                {!link && options.label}
            </NavItem>
        );
    }

    private handleClick = async () => {
        const { context, link, options } = this.props;
        if (!context.isAdmin) {
            const myEnvPreviousUrl = localStorage.getItem("myEnvPreviousUrl");
            if (options.useAsMyEnvLogout) {
                if (myEnvPreviousUrl) {
                    localStorage.removeItem("myEnvPreviousUrl");
                }
                localStorage.removeItem(LOCAL_STORAGE_KEYS.MY_ENV_TOKEN);
                const url = "/myEnvLogout";
                await fetch(url, {
                    method: "GET",
                    headers: {
                        "Access-Control-Allow-Headers": "X-Requested-With",
                        "Access-Control-Allow-Origin": "*",
                    },
                }).then(async (resp) => {
                    if (!resp.ok) {
                        throw new Error(`GET ${url} responded with status ${resp.status} (${await resp.text()})`);
                    }
                    return resp.json();
                });
            }
            if (context.site.useGTM) {
                (window as any).dataLayer.push({
                    event: "PageView",
                    meta: {
                        referrer: window.location.href,
                        pathname: link,
                    },
                });
            }
        }
    };
}
const inlineIconStyles = (options: WidgetOptions) => {
    const imageUrl = options.mxtsImage?.originalUrl;
    if (options) {
        return {
            ...(options.iconType === "mxtsimage" && { backgroundImage: "url(" + imageUrl + ")" || undefined }),
        };
    }
    return {};
};
